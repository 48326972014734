"use strict";

import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

//headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }

_axios.interceptors.request.use(function(request: any) {
  const token = localStorage.getItem("token");
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
    //_axios.defaults.headers.common = {'Authorization': `bearer ${token}`}
  }
  //request.withCredentials = true;
  return request;
});

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    if (process.env.NODE_ENV != "production") {
      console.log("Starting Request: ", config);
    }
    return config;
  },
  function(error) {
    // Do something with request error
    if (process.env.NODE_ENV != "production") {
      console.log("Error in Request: ", error);
    }
    return Promise.reject(error);
  }
);

let refreshing_token: any = null;

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    if (process.env.NODE_ENV != "production") {
      console.log("Response:", response);
    }
    return response;
  },
  async function(error) {
    const originalConfig = error.config;
    if (originalConfig.url !== "/login" && error.response) {
      // Access Token was expired
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          //Handle multiple refresh call
          let refreshToken = localStorage.getItem("refreshToken");
          refreshing_token = refreshing_token
            ? refreshing_token
            : store.dispatch("auth/tryRefreshToken", {
                refreshToken: refreshToken,
              });
          //Refresh JWT token with refresh token
          await refreshing_token;
          refreshing_token = null;

          // Resend original call to be executed again
          return _axios(originalConfig);
        } catch (_error) {
          // Logout user since cannot refresh token
          if (router.currentRoute.path !== "/login") {
            store.dispatch("auth/logout");
          }
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(error);
  }
);

// Plugin.install = function(Vue, options) {
//   Vue.axios = _axios;
//   window.axios = _axios;
//   Object.defineProperties(Vue.prototype, {
//     axios: {
//       get() {
//         return _axios;
//       }
//     },
//     $axios: {
//       get() {
//         return _axios;
//       }
//     },
//   });
// };

// Vue.use(Plugin)

export default _axios;
