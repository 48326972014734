import { Snackbar } from "@/models/snackbar";
import axios from "@/plugins/axios";
import { ActionContext } from "vuex";

type GeneralState = {
  snackbar: Snackbar;
};

const initialState = () => ({
  snackbar: {} as Snackbar,
});

export const state: GeneralState = initialState();

export const mutations = {
  RESET(state: GeneralState) {
    state = initialState();
  },
  SET_SNACK_WHOLE(state: GeneralState, payload: Snackbar) {
    state.snackbar = payload;
  },
  SET_SNACK_ISSNACKING(state: GeneralState, payload: boolean) {
    state.snackbar.isSnacking = payload;
  },
  SET_SNACK_COLOR(state: GeneralState, payload: string) {
    state.snackbar.snackColor = payload;
  },
  SET_SNACK_MESSAGE(state: GeneralState, payload: string) {
    state.snackbar.snackMessage = payload;
  },
  SET_SNACK_TIMEOUT(state: GeneralState, payload: string) {
    state.snackbar.snackTimeout = payload;
  },
};

export const actions = {
  reset({ commit }: any) {
    commit("RESET");
  },
  changeSnackbar({ commit }: any, payload: any) {
    commit("SET_SNACK_WHOLE", payload);
  },
  changeSnackIsSnacking({ commit }: any, payload: any) {
    commit("SET_SNACK_ISSNACKING", payload);
  },
  changeSnackColor({ commit }: any, payload: any) {
    commit("SET_SNACK_COLOR", payload);
  },
  changeSnackMessage({ commit }: any, payload: any) {
    commit("SET_SNACK_MESSAGE", payload);
  },
  changeSnackTimeout({ commit }: any, payload: any) {
    commit("SET_SNACK_TIMEOUT", payload);
  },
};

export const getters = {
  getIsSnacking: (state: GeneralState) => state.snackbar.isSnacking,
  getSnackColor: (state: GeneralState) => state.snackbar.snackColor,
  getSnackMessage: (state: GeneralState) => state.snackbar.snackMessage,
  getSnackTimeout: (state: GeneralState) => state.snackbar.snackTimeout,
  getWholeSnack: (state: GeneralState) => state.snackbar,
};
