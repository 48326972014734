
import Vue from "vue";
import i18n from "@/i18n";
import { AuthRole } from "@/models/authrole";

export default Vue.extend({
  name: "AppBar",
  data: () => ({
    currentSurveyItems: [
      {
        title: "navbar.menu.currentsurveysub.newdataentry",
        to: "NewData",
      },
      {
        title: "navbar.menu.currentsurveysub.offlineforms",
        to: "OfflineForms",
      },
    ],
    reportsItems: [
      {
        title: "navbar.menu.reportsub.ddc_reports",
        to: "DDCReports",
        isVisible: true,
      },
      {
        title: "navbar.menu.reportsub.ags_reports",
        to: "AGSReports",
        isVisible: true,
      },
      {
        title: "navbar.menu.reportsub.master_query",
        to: "querymaster",
        isVisible: true,
      },
      {
        title: "navbar.menu.reportsub.product_list",
        to: "productList",
        isVisible: true,
      },
      {
        title: "navbar.menu.reportsub.surveyrespondents",
        to: "SurveyRespondents",
        isVisible: true
      },
    ],
    userItems: [{ title: "navbar.menu.useritems.myprofile", to: "MyProfile" }],
    lang: [
      { title: "English", value: "en" },
      { title: "Français", value: "fr" },
      { title: "Español", value: "es" },
    ],
    settingItems: [
      { title: "navbar.menu.settingitems.contactus", to: "ContactUs" },
    ],
    adminItems: [
      {
        title: "navbar.menu.administrationsub.surveysoverview",
        to: "SurveysOverview",
      },
      {
        title: "navbar.menu.currentsurveysub.progressreport",
        to: "ProgressReport",
      },
      {
        title: "navbar.menu.administrationsub.discrepanciesSummary",
        to: "DiscrepanciesSummary",
      },
    ],
    adminUserItems: [
      {
        title: "navbar.menu.administrationsub.usersub.accountslog",
        to: "UsersList",
      },
      {
        title: "navbar.menu.administrationsub.usersub.createaccount",
        to: "RegisterUser",
      },
    ],
    isAuthKey: "123",
    activeLang: "",
  }),
  computed: {
    loggedInUserInitials() {
      return this.$store.getters["auth/getloggedInUserInitials"];
    },
    isAuthenticated() {
      return this.$store.getters["auth/getIsAuthenticated"];
    },
    loggedInUserFullName() {
      return this.$store.getters["auth/getloggedInUserFullName"];
    },
    loggedInUserRole() {
      return this.$store.getters["auth/getLoggedInUserRole"];
    },
    visibleReportItems() {
      return this.reportsItems.filter(function(ri) {
        return ri.isVisible;
      });
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
    },
    canViewSurvey() {
      return this.isAdministrator() || this.isNmoUser() || this.isWfhEmployee();
    },
    canViewReports() {
      return this.isWfhEmployee() || this.isDDCUser() || this.isAdministrator();
    },
    canViewAgsReports() {
      return this.isWfhEmployee() || this.isAdministrator();
    },
    canViewAdminMenu() {
      return this.isAdministrator() || this.isWfhEmployee();
    },
    isDDCUser() {
      return this.loggedInUserRole == AuthRole.DDC;
    },
    isWfhEmployee() {
      return this.loggedInUserRole == AuthRole.WFH;
    },
    isAdministrator() {
      return this.loggedInUserRole == AuthRole.Administrator;
    },
    isNmoUser() {
      return this.loggedInUserRole == AuthRole.NMO;
    },
    handleMenuItemClick(lang: any) {
      this.activeLang = lang.title;
      i18n.locale = lang.value;
    },
    initLang() {
      var curLang = this.lang.find((x) => x.value == i18n.locale)?.title;
      if (curLang == undefined || curLang == null) curLang = "English";

      return curLang;
    },
  },
  watch: {
    isAuthenticated: {
      deep: true,
      handler() {
        this.isAuthKey += 1;
      },
    },
  },
  created() {
    this.activeLang = this.initLang();

    this.reportsItems[0].isVisible = this.canViewReports();
    this.reportsItems[1].isVisible = this.canViewAgsReports();
    this.reportsItems[2].isVisible = this.canViewReports();
    this.reportsItems[3].isVisible = this.canViewReports();
  },
});
