import Vue from "vue";
import Vuex from "vuex";

import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";

Vue.use(Vuex);

// Load store modules dynamically.
const requireContext = require.context("./modules", false, /.*\.ts$/);
const modules = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.ts$)/g, ""), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }

    return { ...modules, [name]: module };
  }, {});

export default new Vuex.Store({
  //Component appelle action, qui appelle 1 a * mutation, qui modifie le state
  state: {
    //test value
    value: 0,
  },
  getters,
  mutations,
  actions,
  modules: { ...modules }, // ... = merge de tableau ( objet )
});
