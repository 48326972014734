import axios from "@/plugins/axios";
import { OrganizationSection } from "@/models/organizationsection";
import { CompletedBySection } from "@/models/completedbysection";
import { CountriesSection } from "@/models/countriessection";
import { DataSourceSection } from "@/models/datasourcesection";
import { ProductSection } from "@/models/productsection";
import { CurrencySection } from "@/models/currencysection";
import { CareSection } from "@/models/caresection";
import { CommentSection } from "@/models/commentsection";
import { Manufacturer } from "@/models/manufacturer";
import { ProductType } from "@/models/producttype";
import { Survey } from "@/models/survey";
import { ActionContext } from "vuex";
import { FactorUseSection } from "@/models/factorusesection";
import { PatientSection } from "@/models/patientsection";
import { ImportLog } from "@/models/importlog";
import { SectionsStatuses } from "@/models/sectionsstatuses";
import moment from "moment";

type DataManagerState = {
  organizationSection: OrganizationSection;
  organizationSectionSaveRes: null | [];
  completedBySection: CompletedBySection;
  completedBySectionSaveRes: null | [];
  dataSourceSection: DataSourceSection;
  dataSourceSectionSaveRes: null | [];
  productSection: ProductSection[];
  productSectionSaveRes: null | [];
  currencySection: CurrencySection;
  currencySectionSaveRes: null | [];
  careSection: CareSection;
  careSectionSaveRes: null | [];
  factorUseSection: FactorUseSection;
  factorUseSectionSaveRes: null | [];
  patientSection: PatientSection;
  patientSectionSaveRes: null | [];
  sectionsStatuses: SectionsStatuses;
  commentSection: CommentSection[];
  countrySelected: string;
  isLoading: boolean;
  error: string | null;
  countries: CountriesSection[];
  fetchCountryByIdResult: {};
  manufacturers: Manufacturer[];
  productTypes: ProductType[];
  importLog: ImportLog[];
  currentSurvey: Survey | null;
  surveys: Survey[];
  tabSelected: null | Number;
  isReadOnly: boolean;
  hasUnsavedChanges: boolean;
  surveyApprovalResult: {};
  isPrinting: boolean
};
//Making an initial state so "reset" is possible.
const initialState = () => ({
  organizationSection: {} as OrganizationSection,
  organizationSectionSaveRes: null,
  completedBySection: {} as CompletedBySection,
  completedBySectionSaveRes: null,
  dataSourceSection: {} as DataSourceSection,
  dataSourceSectionSaveRes: null,
  productSection: [],
  productSectionSaveRes: null,
  currencySection: {} as CurrencySection,
  currencySectionSaveRes: null,
  factorUseSection: {} as FactorUseSection,
  factorUseSectionSaveRes: null,
  careSection: {} as CareSection,
  careSectionSaveRes: null,
  patientSection: {} as PatientSection,
  patientSectionSaveRes: null,
  sectionsStatuses: {} as SectionsStatuses,
  commentSection: [] as CommentSection[],
  countrySelected: "",
  isLoading: false,
  error: null,
  countries: [],
  fetchCountryByIdResult: {},
  manufacturers: [],
  productTypes: [],
  importLog: [],
  currentSurvey: null,
  surveys: [],
  tabSelected: 0,
  isReadOnly: true,
  hasUnsavedChanges: false,
  surveyApprovalResult: {},
  isPrinting: false
});

export const state: DataManagerState = initialState();

//po-async
export const mutations = {
  RESET(state: DataManagerState) {
    state = initialState();
  },
  SET_ORGANIZATION_SECTION(
    state: DataManagerState,
    organizationSection: OrganizationSection
  ) {
    state.organizationSection = organizationSection;
  },
  SAVE_ORGANIZATION_SECTION_RESULT(
    state: DataManagerState,
    organizationSection: any
  ) {
    state.organizationSectionSaveRes = organizationSection.saveRes;
    state.organizationSection = organizationSection.resData;
    state.hasUnsavedChanges = false;
  },
  SET_COMPLETEDBY_SECTION(
    state: DataManagerState,
    completedBySection: CompletedBySection
  ) {
    state.completedBySection = completedBySection;
  },
  SAVE_COMPLETEDBY_SECTION_RESULT(
    state: DataManagerState,
    completedBySection: any
  ) {
    state.completedBySectionSaveRes = completedBySection.saveRes;
    state.completedBySection = completedBySection.resData;
    state.hasUnsavedChanges = false;
  },
  SET_DATASOURCE_SECTION(
    state: DataManagerState,
    dataSourceSection: DataSourceSection
  ) {
    state.dataSourceSection = dataSourceSection;
  },
  SAVE_DATASOURCE_SECTION_RESULT(
    state: DataManagerState,
    dataSourceSection: any
  ) {
    state.dataSourceSectionSaveRes = dataSourceSection.saveRes;
    state.dataSourceSection = dataSourceSection.resData;
    state.hasUnsavedChanges = false;
  },
  SET_PRODUCT_SECTION(state: DataManagerState, productSection: []) {
    state.productSection = productSection;
  },
  SAVE_PRODUCT_SECTION_RESULT(state: DataManagerState, saveRes: any) {
    state.productSectionSaveRes = saveRes;
    state.hasUnsavedChanges = false;
  },
  SET_CURRENCY_SECTION(
    state: DataManagerState,
    currencySection: CurrencySection
  ) {
    state.currencySection = currencySection;
  },
  SAVE_CURRENCY_SECTION_RESULT(state: DataManagerState, currencySection: any) {
    state.currencySectionSaveRes = currencySection.saveRes;
    state.currencySection = currencySection.resData;
    state.hasUnsavedChanges = false;
  },
  SET_FACTORUSE_SECTION(
    state: DataManagerState,
    factorUseSection: FactorUseSection
  ) {
    state.factorUseSection = factorUseSection;
  },
  SAVE_FACTORUSE_SECTION_RESULT(
    state: DataManagerState,
    factorUseSection: any
  ) {
    state.factorUseSectionSaveRes = factorUseSection.saveRes;
    state.factorUseSection = factorUseSection.resData;
    state.hasUnsavedChanges = false;
  },
  SET_CARE_SECTION(state: DataManagerState, careSection: CareSection) {
    state.careSection = careSection;
  },
  SAVE_CARE_SECTION_RESULT(state: DataManagerState, payload: any) {
    state.careSectionSaveRes = payload.saveRes;
    state.careSection = payload.resData;
    state.hasUnsavedChanges = false;
  },
  SET_PATIENT_SECTION(state: DataManagerState, patientSection: PatientSection) {
    state.patientSection = patientSection;
  },
  SAVE_PATIENT_SECTION_RESULT(state: DataManagerState, patientSection: any) {
    state.patientSectionSaveRes = patientSection.resData;
    state.patientSection = patientSection.newData;
  },
  SET_SECTIONS_STATUSES(
    state: DataManagerState,
    sectionsStatuses: SectionsStatuses
  ) {
    state.sectionsStatuses = sectionsStatuses;
  },
  SET_COMMENT_SECTION(state: DataManagerState, commentSection: []) {
    state.commentSection = commentSection;
  },
  SET_COUNTRY_SELECTED(state: DataManagerState, payload: string) {
    state.countrySelected = payload;
  },
  SET_COUNTRIES(state: DataManagerState, payload: []) {
    state.countries = payload;
  },
  SET_FETCHCOUNTRYBYID_RESULT(state: DataManagerState, payload: any) {
    state.fetchCountryByIdResult = payload;
  },
  SET_MANUFACTURERS(state: DataManagerState, payload: []) {
    state.manufacturers = payload;
  },
  SET_PRODUCTTYPES(state: DataManagerState, payload: []) {
    state.productTypes = payload;
  },
  SET_CURRENT_SURVEY(state: DataManagerState, payload: Survey) {
    state.currentSurvey = payload;
  },
  SET_SURVEYS(state: DataManagerState, payload: []) {
    state.surveys = payload;
  },
  SET_TAB_SELECTED(state: DataManagerState, payload: Number) {
    state.tabSelected = payload;
  },
  SET_ISREADONLY(state: DataManagerState, payload: boolean) {
    state.isReadOnly = payload;
  },
  SET_HASUNSAVEDCHANGES(state: DataManagerState, payload: boolean) {
    state.hasUnsavedChanges = payload;
  },
  SET_IMPORTLOG(state: DataManagerState, payload: []) {
    state.importLog = payload;
  },
  APPROVE_SURVEY_RESULT(state: DataManagerState, payload: any) {
    state.surveyApprovalResult = payload;
  },
  SET_IS_PRINTING(state: DataManagerState, payload: boolean) {
    state.isPrinting = payload;
  },
};

//async
export const actions = {
  reset({ commit }: any) {
    commit("RESET");
  },
  fetchCurrentSurvey(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(`/Survey/?beforeCurrentDate=${payload.beforeCurrentDate}`)
        .then((res) => {
          context.commit("SET_CURRENT_SURVEY", res.data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchSurveys(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(`/Survey/All/?beforeCurrentDate=${payload.beforeCurrentDate}`)
        .then((res) => {
          context.commit("SET_SURVEYS", res.data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  addSurvey(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`/Survey/?culture=${payload.culture}`)
        .then((res) => {
          // let resData = res;
          // let newData = payload.newData;
          // context.commit("APPROVE_SURVEY_RESULT", {
          //   resData,
          //   newData,
          // });
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchSurveyOrganizationSection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Survey/OrganizationSection", {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          let ogSection = res.data;
          context.commit("SET_ORGANIZATION_SECTION", ogSection);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  saveSurveyOrganizationSection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .put("/Survey/OrganizationSection", payload.newSurveyOrgData, {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          let saveRes = res;
          //Pk que je voudrais pas updater le store avec le res tse?
          //let ogSection = payload.newSurveyOrgData;
          let resData = res.data;
          context.commit("SAVE_ORGANIZATION_SECTION_RESULT", {
            saveRes,
            resData,
          });
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchSurveyCompletedBySection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Survey/CompletedBySection", {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          let ogSection = res.data;
          context.commit("SET_COMPLETEDBY_SECTION", ogSection);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  saveSurveyCompletedBySection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .put("/Survey/CompletedBySection", payload.newData, {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          let saveRes = res;
          let resData = res.data;
          context.commit("SAVE_COMPLETEDBY_SECTION_RESULT", {
            saveRes,
            resData,
          });
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchSurveyDataSourceSection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Survey/DataSourceSection", {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          let ogSection = res.data;
          context.commit("SET_DATASOURCE_SECTION", ogSection);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  saveSurveyDataSourceSection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .put("/Survey/DataSourceSection", payload.newData, {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          let saveRes = res;
          let resData = res.data;
          context.commit("SAVE_DATASOURCE_SECTION_RESULT", {
            saveRes,
            resData,
          });
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchSurveyProductSection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Survey/ProductSection", {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          context.commit("SET_PRODUCT_SECTION", res.data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  saveSurveyProductSection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .put("/Survey/ProductSection", payload.newData, {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          context.commit("SAVE_PRODUCT_SECTION_RESULT", res);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchSurveyCurrencySection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Survey/CurrencySection", {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          context.commit("SET_CURRENCY_SECTION", res.data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  saveSurveyCurrencySection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .put("/Survey/CurrencySection", payload.newData, {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          let saveRes = res;
          let resData = res.data;
          context.commit("SAVE_CURRENCY_SECTION_RESULT", {
            saveRes,
            resData,
          });
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchSurveyFactorUseSection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Survey/FactorUseSection", {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          context.commit("SET_FACTORUSE_SECTION", res.data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  saveSurveyFactorUseSection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .put("/Survey/FactorUseSection", payload.newData, {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          let saveRes = res;
          let resData = res.data;
          context.commit("SAVE_FACTORUSE_SECTION_RESULT", {
            saveRes,
            resData,
          });
          resolve();
        })
        .catch((e) => console.log(e));
    });
  },
  fetchSurveyPatientSection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Survey/PatientSection", {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          if (res.status == 200)
            context.commit("SET_PATIENT_SECTION", res.data);
          else context.commit("SET_PATIENT_SECTION", {} as PatientSection);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  saveSurveyPatientSection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .put("/Survey/PatientSection", payload.newData, {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          let resData = res;
          let newData = payload.newData;
          context.commit("SAVE_PATIENT_SECTION_RESULT", {
            resData,
            newData,
          });
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchSurveyCareSection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Survey/CareSection", {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          context.commit("SET_CARE_SECTION", res.data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  saveSurveyCareSection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .put("/Survey/CareSection", payload.newData, {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          let saveRes = res;
          let resData = res.data;
          context.commit("SAVE_CARE_SECTION_RESULT", {
            saveRes,
            resData,
          });
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchCommentSection(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Survey/CommentSection", {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          context.commit("SET_COMMENT_SECTION", res.data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  addComment(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post("/Survey/CommentSection", payload.newData, {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          // let resData = res;
          // let newData = payload.newData;
          // context.commit("SAVE_PATIENT_SECTION_RESULT", {
          //   resData,
          //   newData,
          // });
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  changeSurveyApproval(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post("/Survey/Approve", "", {
          params: {
            countryId: payload.countryId,
            surveyKey: state.currentSurvey?.key,
            isApproved: payload.isApproved,
          },
        })
        .then((res) => {
          let resData = res;
          let newData = payload.newData;
          context.commit("APPROVE_SURVEY_RESULT", {
            resData,
            newData,
          });
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchCountries(context: ActionContext<DataManagerState, any>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Country")
        .then((res) => {
          let countries = res.data;
          context.commit("SET_COUNTRIES", countries);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchCountryById(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(`/Country/${payload.id}`)
        .then((res) => {
          context.commit("SET_FETCHCOUNTRYBYID_RESULT", res);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchManufacturers(
    context: ActionContext<DataManagerState, any>
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Manufacturer")
        .then((res) => {
          context.commit("SET_MANUFACTURERS", res.data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchProductTypes(
    context: ActionContext<DataManagerState, any>
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/ProductType")
        .then((res) => {
          context.commit("SET_PRODUCTTYPES", res.data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  changeCurrentSurvey({ commit }: any, payload: Survey) {
    commit("SET_CURRENT_SURVEY", payload);
  },
  changeCountrySelected({ commit }: any, payload: String) {
    commit("SET_COUNTRY_SELECTED", payload);
  },
  changeTabSelected({ commit }: any, payload: Number) {
    commit("SET_TAB_SELECTED", payload);
  },
  changeIsReadOnly({ commit }: any, payload: boolean) {
    commit("SET_ISREADONLY", payload);
  },
  changeHasUnsavedChanges({ commit }: any, payload: boolean) {
    commit("SET_HASUNSAVEDCHANGES", payload);
  },
  uploadSurveyDocument(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post("/Survey/UploadDocument", payload.formData, {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          // let resData = res;
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchImportLog(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Survey/ImportLog", {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          context.commit("SET_IMPORTLOG", res.data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchSectionsStatuses(
    context: ActionContext<DataManagerState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Survey/SectionsStatuses", {
          params: {
            countryId: payload.country.id,
            surveyKey: state.currentSurvey?.key,
          },
        })
        .then((res) => {
          context.commit("SET_SECTIONS_STATUSES", res.data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  setIsPrinting( context: ActionContext<DataManagerState, any>,
                 payload: any):void{
    context.commit("SET_IS_PRINTING",payload);
  }
};

//selecteur
export const getters = {
  getOrganizationSection: (state: DataManagerState) => ({
    ...state.organizationSection,
    //Pour pas faire pointer direct sur lobject..: on clone. Sans ca - on
    //pourrait rewrite le store sans passer par actions/mutation..
  }),
  getOrganizationSectionSaveRes: (state: DataManagerState) =>
    state.organizationSectionSaveRes,
  getCompletedBySection: (state: DataManagerState) => ({
    ...state.completedBySection,
  }),
  getCompletedBySectionSaveRes: (state: DataManagerState) =>
    state.completedBySectionSaveRes,
  getDataSourceSection: (state: DataManagerState) => ({
    ...state.dataSourceSection,
  }),
  getDataSourceSectionSaveRes: (state: DataManagerState) =>
    state.dataSourceSectionSaveRes,
  getProductSection: (state: DataManagerState) =>
    Array.from(state.productSection),
  getProductSectionSaveRes: (state: DataManagerState) =>
    state.productSectionSaveRes,
  getCurrencySection: (state: DataManagerState) => ({
    ...state.currencySection,
  }),
  getCurrencySectionSaveRes: (state: DataManagerState) =>
    state.currencySectionSaveRes,
  getFactorUseSection: (state: DataManagerState) => ({
    ...state.factorUseSection,
  }),
  getFactorUseSectionSaveRes: (state: DataManagerState) =>
    state.factorUseSectionSaveRes,
  getPatientSection: (state: DataManagerState) => ({
    ...state.patientSection,
  }),
  getPatientSectionSaveRes: (state: DataManagerState) =>
    state.patientSectionSaveRes,
  getCareSection: (state: DataManagerState) => ({
    ...state.careSection,
  }),
  getCareSectionSaveRes: (state: DataManagerState) => state.careSectionSaveRes,
  getSectionsStatuses: (state: DataManagerState) => state.sectionsStatuses,
  getCommentSection: (state: DataManagerState) =>
    Array.from(state.commentSection),
  getCountrySelected: (state: DataManagerState) => state.countrySelected,
  getCountries: (state: DataManagerState) => state.countries,
  getFetchCountryByIdResult: (state: DataManagerState) =>
    state.fetchCountryByIdResult,
  getManufacturers: (state: DataManagerState) => state.manufacturers,
  getProductTypes: (state: DataManagerState) => state.productTypes,
  getCurrentSurvey: (state: DataManagerState) => state.currentSurvey,
  getCurrentSurveyYear: (state: DataManagerState) => state.currentSurvey ? state.currentSurvey.year : '',
  getSurveys: (state: DataManagerState) => state.surveys,
  getTabSelected: (state: DataManagerState) => state.tabSelected,
  getIsReadOnly: (state: DataManagerState) => state.isReadOnly,
  getHasUnsavedChanges: (state: DataManagerState) => state.hasUnsavedChanges,
  getImportLog: (state: DataManagerState) => state.importLog,
  getIsSurveyYearModifiable: (state: DataManagerState) => {
    let now = new Date();

    let currentSurvey = state.currentSurvey as Survey;

    if (
      moment(currentSurvey.startDate) <= moment(now) &&
      moment(now) < moment(currentSurvey.endDate)
    )
      return true;
    else return false;
  },
  getOrganizations: (state: DataManagerState) => state.countries,
  isPrinting:(state: DataManagerState) => state.isPrinting
};
