import { Survey } from '../../models/survey';

type DDCReportState = {
  yearSelected: Survey
}

export const state: DDCReportState = {
    yearSelected: {} as Survey
}

export const mutations = {
  SET_YEAR_SELECTED(state: DDCReportState, payload: Survey) {
    state.yearSelected = payload
  },
}

export const actions = {
  changeYearSelected({ commit }: any, payload: Survey) {
    commit('SET_YEAR_SELECTED', payload)
  },
}

export const getters = {
  getYearSelected: (state: DDCReportState) => state.yearSelected,
}