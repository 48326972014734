import axios from "@/plugins/axios";
import { ActionContext } from "vuex";
import { CountriesOverview } from "@/models/countriesoverview";
import { SaveRes } from "@/models/saveres";

type CountriesOverviewState = {
  yearSelected: null | Number;
  countriesOverview: CountriesOverview[];
  gnis: any;
  savePopAndGniRes: any;
};
//Making an initial state so "reset" is possible.
const initialState = () => ({
  yearSelected: 2021,
  countriesOverview: [],
  gnis: [],
  savePopAndGniRes: [],
});

export const state: CountriesOverviewState = initialState();

//po-async
export const mutations = {
  RESET(state: CountriesOverviewState) {
    state = initialState();
  },
  SET_YEAR_SELECTED(state: CountriesOverviewState, payload: number) {
    state.yearSelected = payload;
  },
  SET_COUNTRIES_OVERVIEW(
    state: CountriesOverviewState,
    payload: CountriesOverview[]
  ) {
    state.countriesOverview = payload;
  },
  SET_GNIS(state: CountriesOverviewState, payload: any[]) {
    state.gnis = payload;
  },
  SAVE_POPULATION_AND_GNI_RESULT(
    state: CountriesOverviewState,
    payload: SaveRes
  ) {
    state.savePopAndGniRes = payload;
  },
};

//async
export const actions = {
  reset({ commit }: any) {
    commit("RESET");
  },
  changeYearSelected({ commit }: any, payload: String) {
    commit("SET_YEAR_SELECTED", payload);
  },
  approveSurveys(
    context: ActionContext<CountriesOverviewState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post("/survey/ApproveAll", payload.surveyDataIds, {
          params: {
            isApproved: payload.isApproved,
          },
        })
        .then((res) => {
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchCountriesOverview(
    context: ActionContext<CountriesOverviewState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Country/Overview/All", {
          params: {
            year: payload.year,
          },
        })
        .then((res) => {
          let data = res.data;
          context.commit("SET_COUNTRIES_OVERVIEW", data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchGnis(
    context: ActionContext<CountriesOverviewState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Country/GniType")
        .then((res) => {
          let data = res.data;
          context.commit("SET_GNIS", data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  saveCountryPopulationAndGniChanges(
    context: ActionContext<CountriesOverviewState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .put("/Country/Overview", payload, {})
        .then((res) => {
          context.commit("SAVE_POPULATION_AND_GNI_RESULT", res);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  uploadPopulationsAndGnisData(
    context: ActionContext<CountriesOverviewState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post("/Country/Overview/Upload", payload.formData, {
          params: {
            year: payload.year,
            culture: payload.culture,
          },
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            Accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
        })
        .then((res) => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
//selecteur
export const getters = {
  getYearSelected: (state: CountriesOverviewState) => state.yearSelected,
  getCountriesOverview: (state: CountriesOverviewState) =>
    Array.from(state.countriesOverview),
  getGnis: (state: CountriesOverviewState) => Array.from(state.gnis),
  getSavePopAndGniRes: (state: CountriesOverviewState) =>
    state.savePopAndGniRes,
};
