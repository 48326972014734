import i18n from "@/i18n";
import { AuthRole } from "@/models/authrole";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store/index";
import EmptyLayout from "../views/EmptyLayout.vue";
import Home from "../views/Home.vue";
import Layout from "../views/Layout.vue";

const VIEW_ABOUT = () => import("../views/About.vue");
const VIEW_ADMIN = () => import("../views/Admin/Admin.vue");
const VIEW_NEWDATAENTRY = () => import("../views/NewData.vue");
// const VIEW_PREVIOUSSURVEYS = () => import("../views/NewData.vue");
// const VIEW_REPORTS = () => import("../views/NewData.vue");
const VIEW_OFFLINEFORMS = () => import("../views/OfflineForms.vue");
const VIEW_COUNTRIESOVERVIEW = () =>
  import("../views/Admin/CountriesOverview.vue");
const VIEW_SURVEYRESPONDENTS = () => import("../views/Admin/SurveyRespondents.vue");
const VIEW_PROGRESSREPORT = () => import("../views/Admin/ProgressReport.vue");
const VIEW_AGSREPORT = () => import("../views/Reports/AGSReports.vue");
const VIEW_DDCREPORT = () => import("../views/Reports/DDCReports.vue");
const VIEW_ProductList = () => import("../views/Reports/ProductList.vue");
const VIEW_DiscrepanciesSummary = () =>
  import("../views/Admin/Discrepancies_Summary.vue");
const VIEW_NOTFOUND = () => import("../views/PageNotFound.vue");
const VIEW_LOGIN = () => import("../views/Auth/Login.vue");
const VIEW_MYPROFILE = () => import("../views/User/MyProfile.vue");
const VIEW_REGISTER = () => import("../views/Auth/Register.vue");
const VIEW_RESETPASSWORD = () => import("../views/Auth/ResetPassword.vue");
const VIEW_CONTACTUS = () => import("../views/ContactUs.vue");
const VIEW_REGISTERUSER = () => import("../views/Admin/RegisterUser.vue");
const VIEW_USERSLIST = () => import("../views/Admin/UsersList.vue");
const VIEW_QUERYMASTER = () => import("../views/MASTERQUERY/QueryMaster.vue");

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    component: EmptyLayout,
    children: [
      {
        path: "",
        name: "Login",
        component: VIEW_LOGIN,
      },
    ],
  },
  {
    path: "",
    component: Layout,

    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
        meta: { authorize: [] },
      },
      {
        path: "/about",
        name: "About",
        component: VIEW_ABOUT,
      },
      {
        path: "/logout",
        name: "Logout",
        beforeEnter(to, from, next) {
          if (from.matched.some((x) => x.meta.authorize)) {
            return next("/");
          }
          location.reload();
        },
      },
      {
        path: "/myprofile",
        name: "MyProfile",
        component: VIEW_MYPROFILE,
        meta: { authorize: [] },
      },
      {
        path: "/register",
        name: "Register",
        component: VIEW_REGISTER,
      },
      {
        path: "/resetpassword",
        name: "ResetPassword",
        component: VIEW_RESETPASSWORD,
      },
      {
        path: "/contactus",
        name: "ContactUs",
        component: VIEW_CONTACTUS,
      },
      {
        path: "/newdataentry",
        name: "NewData",
        component: VIEW_NEWDATAENTRY,
        meta: {
          authorize: [AuthRole.Administrator, AuthRole.NMO, AuthRole.WFH],
        },
      },
      {
        path: "/offlineforms",
        name: "OfflineForms",
        component: VIEW_OFFLINEFORMS,
        meta: { authorize: [] },
      },
      {
        path: "/surveysoverview",
        name: "SurveysOverview",
        component: VIEW_COUNTRIESOVERVIEW,
        meta: { authorize: [AuthRole.Administrator, AuthRole.WFH] },
      },
      {
        path: "/progressreport",
        name: "ProgressReport",
        component: VIEW_PROGRESSREPORT,
        meta: { authorize: [AuthRole.Administrator, AuthRole.WFH] },
      },
      {
        path: "/agsreports",
        name: "AGSReports",
        component: VIEW_AGSREPORT,
        meta: {
          authorize: [AuthRole.Administrator, AuthRole.WFH, AuthRole.DDC],
        },
        children: [
          {
            path: "demographic",
            name: "Demographic",
            component: () => import("../components/AGSReports/Demographic.vue"),
          },
          {
            path: "patient",
            name: "Patients",
            component: () => import("../components/AGSReports/Patients.vue"),
          },
          {
            path: "patientGNI",
            name: "PatientsGNI",
            component: () => import("../components/AGSReports/PatientsGNI.vue"),
          },
          {
            path: "factorusage",
            name: "FactorUsage",
            component: () => import("../components/AGSReports/FactorUsage.vue"),
          },
          {
            path: "population",
            name: "PopulationStatistics",
            component: () =>
              import("../components/AGSReports/PopulationStatistics.vue"),
          },
          {
            path: "bleeding",
            name: "BleedingDisorders",
            component: () =>
              import("../components/AGSReports/BleedingDisorders.vue"),
          },
          {
            path: "gender",
            name: "GenderDistribution",
            component: () =>
              import("../components/AGSReports/GenderDistribution.vue"),
          },
          {
            path: "genderLatest",
            name: "GenderDistributionLatest",
            component: () =>
              import("../components/AGSReports/GenderDistributionLatest.vue"),
          },
          {
            path: "inhibitor",
            name: "Inhibitors",
            component: () => import("../components/AGSReports/Inhibitors.vue"),
          },
          {
            path: "agehemophiliaA",
            name: "AgeHemophiliaA",
            props: { type: "1", isLatest: false },
            component: () => import("../components/AGSReports/Age.vue"),
          },
          {
            path: "agehemophiliaB",
            name: "AgeHemophiliaB",
            props: { type: "2", isLatest: false },
            component: () => import("../components/AGSReports/Age.vue"),
          },
          {
            path: "agehemophiliaunknown",
            name: "AgeHemophiliaUnknown",
            props: { type: "3", isLatest: false },
            component: () => import("../components/AGSReports/Age.vue"),
          },
          {
            path: "agevwd",
            name: "AgeVWD",
            props: { type: "4", isLatest: false },
            component: () => import("../components/AGSReports/Age.vue"),
          },
          {
            path: "agehemophiliaALatest",
            name: "AgeHemophiliaALatest",
            props: { type: "1", isLatest: true },
            component: () => import("../components/AGSReports/Age.vue"),
          },
          {
            path: "agehemophiliaBLatest",
            name: "AgeHemophiliaBLatest",
            props: { type: "2", isLatest: true },
            component: () => import("../components/AGSReports/Age.vue"),
          },
          {
            path: "agehemophiliaULatest",
            name: "AgeHemophiliaULatest",
            props: { type: "3", isLatest: true },
            component: () => import("../components/AGSReports/Age.vue"),
          },
          {
            path: "agevwdLatest",
            name: "AgeVWDLatest",
            props: { type: "4", isLatest: true },
            component: () => import("../components/AGSReports/Age.vue"),
          },
          {
            path: "HivHcvInfection",
            name: "HivHcvInfection",
            component: () =>
              import("../components/AGSReports/HivAndHcvInfection.vue"),
          },
          {
            path: "SeverityHemophiliaByGenderAndGNI",
            name: "severityHemophiliaByGenderAndGNI",
            component: () =>
              import(
                "../components/AGSReports/SeverityHemophiliaByGenderAndGNI.vue"
              ),
          },
          {
            path: "percentagePatientsProphylaxis",
            name: "percentagePatientsProphylaxis",
            component: () =>
              import(
                "../components/AGSReports/PercentagePatientsProphylaxis.vue"
              ),
          },
          {
            path: "FVIIIperCountry",
            name: "FVIIIperCountry",
            component: () =>
              import("../components/AGSReports/FVIIIperCountry.vue"),
          },
          {
            path: "FIXperCountry",
            name: "FIXperCountry",
            component: () =>
              import("../components/AGSReports/FIXperCountry.vue"),
          },
          {
            path: "FactorVIIIusage",
            name: "FactorVIIIusage",
            component: () =>
              import("../components/AGSReports/FactorVIIIUsage.vue"),
          },
          {
            path: "FactorIXUsage",
            name: "FactorIXUsage",
            component: () =>
              import("../components/AGSReports/FactorIXUsage.vue"),
          },
          {
            path: "FVIIIUsagebyGNI",
            name: "FVIIIUsagebyGNI",
            component: () =>
              import("../components/AGSReports/FVIIIUsagebyGNI.vue"),
          },
          {
            path: "FIXUsagebyGNI",
            name: "FIXUsagebyGNI",
            component: () =>
              import("../components/AGSReports/FIXUsagebyGNI.vue"),
          },
          {
            path: "FVIIIUsagebyGNIAndPatiens",
            name: "FVIIIUsagebyGNIAndPatiens",
            meta: {
              idFactor: 1,
            },
            component: () =>
              import("../components/AGSReports/FactorUsagebyGNIAndPatiens.vue"),
          },
          {
            path: "FIXUsagebyGNIAndPatiens",
            name: "FIXUsagebyGNIAndPatiens",
            meta: {
              idFactor: 2,
            },
            component: () =>
              import("../components/AGSReports/FactorUsagebyGNIAndPatiens.vue"),
          },
          {
            path: "OtherBleedingDisordersLatestData",
            name: "OtherBleedingDisordersLatestData",
            component: () =>
              import(
                "../components/AGSReports/OtherBleedingDisordersLatestData.vue"
              ),
          },
          {
            path: "TreatmentByGNIOverTime",
            name: "TreatmentByGNIOverTime",
            component: () =>
              import("../components/AGSReports/TreatmentByGNIOverTime.vue"),
          },
        ],
      },
      {
        path: "/Product_List",
        name: "productList",
        component: VIEW_ProductList,
        meta: {
          authorize: [AuthRole.Administrator, AuthRole.WFH, AuthRole.DDC],
        },
      },
      {
        path: "/ddcreports",
        name: "DDCReports",
        component: VIEW_DDCREPORT,
        meta: {
          authorize: [AuthRole.Administrator, AuthRole.WFH, AuthRole.DDC],
        },
        children: [
          {
            path: "DataSourceInformation",
            name: "datasourceinformation",
            component: () =>
              import("../components/DDCReports/dataSourceInformation.vue"),
          },
          {
            path: "datasourceAllData",
            name: "datasourceAllData",
            component: () =>
              import("../components/DDCReports/dataSourceAllData.vue"),
          },
          {
            path: "Hemophilia_vWD_by_Gender",
            name: "hemophiliavWDbyGender",
            component: () =>
              import("../components/DDCReports/HemophiliavWDbyGender.vue"),
          },
          {
            path: "Hemophilia_vWD_by_Age",
            name: "hemophiliavWDbyAge",
            component: () =>
              import("../components/DDCReports/HemophiliavWDbyAge.vue"),
          },
          {
            path: "rareBlendingDisorderByGender",
            name: "rareBlendingDisorderbyGender",
            component: () =>
              import(
                "../components/DDCReports/RareBleedingDisorderbyGender.vue"
              ),
          },
          {
            path: "hemophiliaA&BSeverity",
            name: "hemophiliaA&BSeverity",
            component: () =>
              import("../components/DDCReports/HemophiliaA-and-B-Severity.vue"),
          },
          {
            path: "vWD-Severity",
            name: "vWD-Severity",
            component: () =>
              import("../components/DDCReports/vWD-Severity.vue"),
          },
          {
            path: "hemophiliavWDInhibitors",
            name: "hemophiliavWDInhibitors",
            component: () =>
              import("../components/DDCReports/HemophiliavWDInhibitors.vue"),
          },
          {
            path: "hemophiliaProductUse",
            name: "hemophiliaProductUse",
            component: () =>
              import("../components/DDCReports/HemophiliaProductsUse.vue"),
          },
          {
            path: "vWDProductUse",
            name: "vWDProductUse",
            component: () =>
              import("../components/DDCReports/vWDProductUse.vue"),
          },
          {
            path: "HIV_HCV",
            name: "hiv_hcv",
            component: () => import("../components/DDCReports/HIV-HCV.vue"),
          },
          {
            path: "Deaths",
            name: "deaths",
            component: () => import("../components/DDCReports/Deaths.vue"),
          },
          {
            path: "Care",
            name: "care",
            component: () => import("../components/DDCReports/Care.vue"),
          },
          {
            path: "Treatment",
            name: "treatment",
            component: () => import("../components/DDCReports/Treatment.vue"),
          },
          {
            path: "treatmentAllData",
            name: "treatmentAllData",
            component: () => import("../components/DDCReports/TreatmentAllData.vue"),
          }
        ],
      },
      {
        path: "/discrepanciesSummary",
        name: "DiscrepanciesSummary",
        component: VIEW_DiscrepanciesSummary,
        meta: { authorize: [AuthRole.Administrator, AuthRole.WFH] },
      },
      {
        path: "/surveyrespondents",
        name: "SurveyRespondents",
        component: VIEW_SURVEYRESPONDENTS,
        meta: { authorize: [AuthRole.Administrator, AuthRole.WFH, AuthRole.DDC] },
      },
      {
        path: "/admin",
        name: "Admin",
        component: VIEW_ADMIN,
        meta: { authorize: [AuthRole.Administrator] },
      },
      {
        path: "/admin/registeruser",
        name: "RegisterUser",
        component: VIEW_REGISTERUSER,
        meta: { authorize: [AuthRole.Administrator] },
      },
      {
        path: "/admin/userslog",
        name: "UsersList",
        component: VIEW_USERSLIST,
        meta: { authorize: [AuthRole.Administrator] },
      },
      {
        path: "/admin/querymaster",
        name: "querymaster",
        component: VIEW_QUERYMASTER,
        meta: { authorize: [AuthRole.Administrator, AuthRole.DDC] },
      },
      {
        path: "/*",
        name: i18n.t("routing.pagenotfound").toString(),
        component: VIEW_NOTFOUND,
      }
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history", // Remove the # from Url.
  base: process.env.BASE_URL,
});

router.beforeEach((to, from, next) => {
  //Try to autolog
  store.dispatch("auth/tryAutoLogin");

  //Redirect to login page if not logged in and trying to access a restricted page
  if (to.meta?.authorize) {
    //Check if user logged in
    if (!store.getters["auth/getIsAuthenticated"]) {
      return next({ path: "/login", query: { returnUrl: to.path } });
    }

    if (
      to.meta?.authorize.length &&
      !to.meta?.authorize.includes(store.getters["auth/getLoggedInUserRole"])
    ) {
      //Role not authorised so redirect to home page
      return next({ path: "/" });
    }
  }

  next();
});

export default router;
