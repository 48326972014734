import axios from "@/plugins/axios";
import { ActionContext } from "vuex";
import { User } from "../../models/user";
import { Role } from "../../models/role";
import { ComboBox } from "../../models/combobox";
import VueI18n from "vue-i18n";

type UsersState = {
  users: User[];
  userRegisterRes: null | {};
  roles: Role[];
  userInfos: {};
  userInfosSaveRes: {};
  organizations: ComboBox[];
};

const initialState = () => ({
  users: [],
  userRegisterRes: null,
  roles: [],
  userInfos: {},
  userInfosSaveRes: {},
  organizations: [],
});

export const state: UsersState = initialState();

export const mutations = {
  RESET(state: UsersState) {
    state = initialState();
  },
  SET_USERS(state: UsersState, payload: User[]) {
    state.users = payload;
  },
  SET_ROLES(state: UsersState, payload: Role[]) {
    state.roles = payload;
  },
  SET_ORGANIZATIONS(state: UsersState, payload: ComboBox[]) {
    state.organizations = payload;
  },
  SET_USER_INFOS(state: UsersState, payload: any) {
    state.userInfos = payload;
  },
  SAVE_USER_INFOS_RESULT(state: UsersState, payload: any) {
    state.userInfosSaveRes = payload;
  },
  USER_REGISTER_RESULT(state: UsersState, payload: any) {
    state.userRegisterRes = payload;
  },
};

export const actions = {
  reset({ commit }: any) {
    commit("RESET");
  },
  fetchUsers(
    context: ActionContext<UsersState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/User")
        .then((res) => {
          context.commit("SET_USERS", res.data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchOrganizations(
    context: ActionContext<UsersState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Organization/country", { params: { id: payload } })
        .then((res) => {
          context.commit("SET_ORGANIZATIONS", res.data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchRoles(
    context: ActionContext<UsersState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get("/Role")
        .then((res) => {
          context.commit("SET_ROLES", res.data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  fetchUserInfos(
    context: ActionContext<UsersState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(`/User/${payload}`)
        .then((res) => {
          context.commit("SET_USER_INFOS", res.data);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  saveUserInfos(
    context: ActionContext<UsersState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .put(`/User/UpdateProfile/${payload.id}`, {
          // firstName: "Alex",
          // lastName: "Test",
          phone: payload.phone,
          roleInOrganization: payload.role,
        })
        .then((res) => {
          context.commit("SAVE_USER_INFOS_RESULT", res);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  userRegister(
    context: ActionContext<UsersState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`/User/Register/?culture=${payload.culture}`, payload.data)
        .then((res) => {
          let saveRes = res;
          context.commit("USER_REGISTER_RESULT", saveRes);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  userApprove(
    context: ActionContext<UsersState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`/User/Approve/?culture=${payload.culture}`, payload.data)
        .then((res) => {
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  userCreate(
    context: ActionContext<UsersState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post("/User", payload)
        .then((res) => {
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  userDelete(
    context: ActionContext<UsersState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .delete(`/User/${payload}`)
        .then((res) => {
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
};

export const getters = {
  getUsers: (state: UsersState) => Array.from(state.users),
  getRoles: (state: UsersState) => Array.from(state.roles),
  getUserRegisterRes: (state: UsersState) => state.userRegisterRes,
  getUserInfos: (state: UsersState) => state.userInfos,
  getUserInfosSaveRes: (state: UsersState) => state.userInfosSaveRes,
  getOrganization: (state: UsersState) => state.organizations,
};
