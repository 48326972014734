import axios from "@/plugins/axios";
import { ActionContext } from "vuex";

import router from "../../router/index";

type AuthState = {
  idToken: string | null;
  loggedInUserId: string | null;
  loggedInUserRole: string | null;
  loggedInUserFirstName: string | null;
  loggedInUserLastName: string | null;
  loggedInUserFullName: string | null;
  loggedInUserOrgId: string | null;
  loggedInUserCountryId: string | null;
  loginRes: {} | null;
  forgotPasswordResult: {} | null;
  resetPasswordResult: {} | null;
};

const initialState = () => ({
  idToken: null,
  loggedInUserId: null,
  loggedInUserRole: null,
  loggedInUserFirstName: null,
  loggedInUserLastName: null,
  loggedInUserFullName: null,
  loggedInUserOrgId: null,
  loggedInUserCountryId: null,
  loginRes: null,
  forgotPasswordResult: null,
  resetPasswordResult: null,
});

export const state: AuthState = initialState();

export const mutations = {
  RESET(state: AuthState) {
    state = initialState();
  },
  SET_AUTH_USER(state: AuthState, userData: any) {
    state.idToken = userData.token;
    state.loggedInUserId = userData.userId;
    state.loggedInUserRole = userData.role;
    state.loggedInUserFirstName = userData.firstname;
    state.loggedInUserLastName = userData.lastname;
    state.loggedInUserFullName =
      state.loggedInUserFirstName + " " + state.loggedInUserLastName;
    state.loggedInUserOrgId = userData.orgId;
    state.loggedInUserCountryId = userData.countryId;
    state.loginRes = userData.loginRes;
  },
  AUTOSET_AUTH_USER(state: AuthState, userData: any) {
    state.idToken = userData.token;
    state.loggedInUserId = userData.userId;
    state.loggedInUserRole = userData.role;
    state.loggedInUserFirstName = userData.firstname;
    state.loggedInUserLastName = userData.lastname;
    state.loggedInUserFullName =
      state.loggedInUserFirstName + " " + state.loggedInUserLastName;
    state.loggedInUserOrgId = userData.orgId;
    state.loggedInUserCountryId = userData.countryId;
  },
  CLEAR_AUTH_DATA(state: AuthState) {
    state.idToken = null;
    state.loggedInUserId = null;
    state.loggedInUserFirstName = null;
    state.loggedInUserLastName = null;
    state.loggedInUserFullName = null;
    state.loggedInUserRole = null;
    state.loggedInUserOrgId = null;
    state.loggedInUserCountryId = null;
  },
  SET_FORGOT_PASSWORD_RESULT(state: AuthState, userData: any) {
    state.forgotPasswordResult = userData.forgotPasswordResult;
  },
  SET_RESET_PASSWORD_RESULT(state: AuthState, payload: any) {
    state.resetPasswordResult = payload;
  },
};

export const actions = {
  reset({ commit }: any) {
    commit("RESET");
  },
  login(context: ActionContext<AuthState, any>, payload: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post("/User/Authenticate", payload)
        .then((res) => {
          const now = new Date();
          // 30min * 60 seconds * 1000 milliseconds
          const expirationDate = now.getTime() + 3600000;
          localStorage.setItem("token", res.data.jwtToken);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          localStorage.setItem("userId", res.data.key);
          localStorage.setItem("userFirstName", res.data.firstName);
          localStorage.setItem("userLastName", res.data.lastName);
          localStorage.setItem("userOrgId", res.data.organizationId);
          localStorage.setItem("userCountryId", res.data.countryId);
          localStorage.setItem("userRole", res.data.role?.name_en);
          localStorage.setItem("expirationDate", expirationDate.toString()); //should probably validation with token backend
          context.commit("SET_AUTH_USER", {
            token: res.data.jwtToken,
            refreshToken: res.data.refreshToken,
            firstname: res.data.firstName,
            lastname: res.data.lastName,
            userId: res.data.key,
            orgId: res.data.organizationId,
            countryId: res.data.countryId,
            role: res.data.role?.name_en,
            loginRes: res,
          });
          // context.dispatch("setLogoutTimer", expirationDate);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  tryRefreshToken(
    context: ActionContext<AuthState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post("/User/RefreshToken", payload)
        .then((res) => {
          const now = new Date();
          // 30min * 60 seconds * 1000 milliseconds
          const expirationDate = now.getTime() + 3600000;
          localStorage.setItem("token", res.data.jwtToken);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          localStorage.setItem("userId", res.data.key);
          localStorage.setItem("userFirstName", res.data.firstName);
          localStorage.setItem("userLastName", res.data.lastName);
          localStorage.setItem("userOrgId", res.data.organizationId);
          localStorage.setItem("userCountryId", res.data.countryId);
          localStorage.setItem("userRole", res.data.role?.name_en);
          localStorage.setItem("expirationDate", expirationDate.toString()); //should probably validation with token backend
          context.commit("SET_AUTH_USER", {
            token: res.data.jwtToken,
            refreshToken: res.data.refreshToken,
            firstname: res.data.firstName,
            lastname: res.data.lastName,
            userId: res.data.key,
            orgId: res.data.organizationId,
            countryId: res.data.countryId,
            role: res.data.role?.name_en,
            loginRes: res,
          });
          // context.dispatch("setLogoutTimer", expirationDate);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  tryAutoLogin({ commit }: any) {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    if (!token) {
      return;
    }
    const expirationDate = new Date(
      parseInt(localStorage.getItem("expirationDate") as string) //should probably validation with token backend
    );
    const now = new Date();
    if (now >= expirationDate) {
      return;
    }
    const userId = localStorage.getItem("userId");
    const userFirstName = localStorage.getItem("userFirstName");
    const userLastName = localStorage.getItem("userLastName");
    const userOrgId = localStorage.getItem("userOrgId");
    const userCountryId = localStorage.getItem("userCountryId");
    const userRole = localStorage.getItem("userRole");

    commit("AUTOSET_AUTH_USER", {
      token: token,
      refreshToken: refreshToken,
      userId: userId,
      firstname: userFirstName,
      lastname: userLastName,
      orgId: userOrgId,
      countryId: userCountryId,
      role: userRole,
    });
  },
  logout({ commit }: any) {
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userFirstName");
    localStorage.removeItem("userLastName");
    localStorage.removeItem("userOrgId");
    localStorage.removeItem("userCountryId");
    localStorage.removeItem("userRole");
    commit("CLEAR_AUTH_DATA");
    router.replace("/login");
  },
  forgotPassword(
    context: ActionContext<AuthState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`/User/ForgotPassword/?culture=${payload.culture}`, payload.data)
        .then((res) => {
          context.commit("SET_FORGOT_PASSWORD_RESULT", {
            forgotPasswordResult: res,
          });
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
  resetPassword(
    context: ActionContext<AuthState, any>,
    payload: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post("/User/ResetPassword", payload)
        .then((res) => {
          context.commit("SET_RESET_PASSWORD_RESULT", res);
          resolve();
        })
        .catch((e) => reject(e));
    });
  },
};

export const getters = {
  getloggedInUserFullName: (state: AuthState) => state.loggedInUserFullName,
  getloggedInUserInitials: (state: AuthState) => {
    let initials = "";
    if (!!state.loggedInUserFirstName)
      initials += state.loggedInUserFirstName.charAt(0).toUpperCase();
    if (!!state.loggedInUserLastName)
      initials += state.loggedInUserLastName.charAt(0).toUpperCase();

    return initials;
  },
  getLoggedInUserOrgId: (state: AuthState) => state.loggedInUserOrgId,
  getLoggedInUserCountryId: (state: AuthState) => state.loggedInUserCountryId,
  getLoggedInUserId: (state: AuthState) => state.loggedInUserId,
  getIsAuthenticated: (state: AuthState) => state.idToken !== null,
  getLoggedInUserRole: (state: AuthState) => state.loggedInUserRole,
  getLoginResult: (state: AuthState) => state.loginRes,
  getForgotPasswordResult: (state: AuthState) => state.forgotPasswordResult,
  getResetPasswordResult: (state: AuthState) => state.resetPasswordResult,
};
