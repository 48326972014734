
import Vue from "vue";
import i18n from "@/i18n";

export default Vue.extend({
  name: "App",
  data: () => ({}),
  computed: {
    wholeSnack: {
      get() {
        return this.$store.getters["general/getWholeSnack"];
      },
    },
    isSnacking: {
      get() {
        return this.$store.getters["general/getIsSnacking"];
      },
      set(bool) {
        this.$store.dispatch("general/changeSnackIsSnacking", bool);
      },
    },
  },
  created() {
    var language = window.navigator.language.substring(0, 2);

    if (language == "en" || language == "fr" || language == "es") {
      i18n.locale = language; //Auto set langue selon browser setting
    } else {
      i18n.locale = "en";
    }
  },
});
