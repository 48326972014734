<template>
  <div>
    <app-bar></app-bar>
    <v-main>
      <v-container fluid>
        <router-view> </router-view>
      </v-container>
    </v-main>
  </div>
</template>
<script>
import AppBar from "../components/Layout/AppBar.vue";
export default {
  name: "Layout",
  components: { AppBar },
  data() {
    return {};
  },
};
</script>
